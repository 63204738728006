@import "@styles/variables";

.root {
  .default-unresolved-icon {
    background-color: #e95b35 !important;
    cursor: default !important;
  }

  .default-revised-icon {
    background-color: #43ac6a !important;
    cursor: default !important;
  }

  .default-resolved-icon {
    background-color: $mystery-grey !important;
    cursor: default !important;
  }

  .retoucher-unresolved-icon {
    background-color: #e95b35 !important;
    &:hover {
      background-color: #43ac6a !important;
    }
  }

  .retoucher-revised-icon {
    background-color: #43ac6a !important;
    &:hover {
      background-color: #e95b35 !important;
    }
  }

  .retoucher-resolved-icon {
    background-color: $mystery-grey !important;
    cursor: default !important;
  }

  .qc-unresolved-icon {
    background-color: #e95b35 !important;
    &:hover {
      background-color: $mystery-grey !important;
    }
  }

  .qc-revised-icon {
    background-color: #43ac6a !important;
    &:hover {
      background-color: $mystery-grey !important;
    }
  }

  .qc-resolved-icon {
    background-color: $mystery-grey !important;
    &:hover {
      background-color: #e95b35 !important;
    }
  }
}
